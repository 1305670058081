import { Link } from "react-router-dom";
import Tab from "../../assets/static/dpg_9.png";
import Immune from "../../assets/static/immune.svg";
import Memory from "../../assets/static/memory.svg";
import Metabolic from "../../assets/static/metabolic.svg";
import Gastro from "../../assets/static/gastro-intestine.svg";
import Glucose from "../../assets/static/glucose.svg";
import Cardio from "../../assets/static/cardio.svg";
import Detox from "../../assets/static/detox.svg";
import Fitness from "../../assets/static/fitness.svg";
import Vitamin from "../../assets/static/vitamin.svg";
import { useTranslation } from "react-i18next";
import useLanguageConditions from "../../hooks/useLanguageConditions";

const ResearchAbstract = () => {
  const { t, i18n } = useTranslation();
  const { isCA } = useLanguageConditions()
  const research = [
    {
      image: Vitamin,
      title: "VITAMINS, MINERALS & OMEGA-3s",
      link: "https://pg3-abstract.s3.amazonaws.com/abstract/21_03_PE_PureGenomicsAbstracts_VitaminMineral_V3.pdf",
    },
    {
      image: Fitness,
      title: "ENERGY & FITNESS",
      link: "https://pg3-abstract.s3.amazonaws.com/abstract/21_03_PE_PureGenomicsAbstracts_EnergyandFitness_V3.pdf",
    },
    {
      image: Detox,
      title: "DETOXIFICATION",
      link: "https://pg3-abstract.s3.amazonaws.com/abstract/21_03_PE_PureGenomicsAbstracts_Detox.pdf",
    },
    {
      image: Fitness,
      title: "INJURY RISK",
      link: "https://pg3-abstract.s3.amazonaws.com/abstract/21_03_PE_PureGenomicsAbstracts_InjuryRisk.pdf",
    },
    {
      image: Cardio,
      title: "CARDIOVASCULAR HEALTH",
      link: "https://pg3-abstract.s3.amazonaws.com/abstract/21_03_PE_PureGenomicsAbstracts_Cardiovascular+Health_V2.pdf",
    },
    {
      image: Fitness,
      title: "EXERCISE RESPONSE",
      link: "https://pg3-abstract.s3.amazonaws.com/abstract/21_03_PE_PureGenomicsAbstracts_ExerciseResponse_V2.pdf",
    },
    {
      image: Glucose,
      title: "GLUCOSE METABOLISM",
      link: "https://pg3-abstract.s3.amazonaws.com/abstract/21_03_PE_PureGenomicsAbstracts_GlucoseMetabolism_V2.pdf",
    },
    {
      image: Gastro,
      title: "GASTROINTESTINAL HEALTH",
      link: "https://pg3-abstract.s3.amazonaws.com/abstract/21_03_PE_PureGenomicsAbstracts_GastrointestinalHealth_V3.pdf",
    },
    {
      image: Metabolic,
      title: "METABOLIC & WEIGHT MANAGEMENT",
      link: "https://pg3-abstract.s3.amazonaws.com/abstract/21_03_PE_PureGenomicsAbstracts_WeightManagement.pdf",
    },
    {
      image: Memory,
      title: "COGNITIVE HEALTH & MEMORY",
      link: "https://pg3-abstract.s3.amazonaws.com/abstract/21_03_PE_PureGenomicsAbstracts_CognitiveHealthandMemory_V3.pdf",
    },
    {
      image: Immune,
      title: "IMMUNE HEALTH",
      link: "https://pg3-abstract.s3.amazonaws.com/abstract/22_11_PE_PureGenomicsAbstracts_ImmuneHealth_V1.pdf",
    },
    { image: "", title: "", link: "" },
  ];
  const ca_research = [
    {
      image: Vitamin,
      title: "VITAMINS, MINERALS & OMEGA-3s",
      link: "https://pg3-abstract.s3.us-east-1.amazonaws.com/abstract/CA_21_03_PE_PureGenomicsAbstracts_VitaminMineral_V3.pdf",
    },
    {
      image: Fitness,
      title: "ENERGY & FITNESS",
      link: "https://pg3-abstract.s3.us-east-1.amazonaws.com/abstract/CA_21_03_PE_PureGenomicsAbstracts_EnergyandFitness_V3.pdf",
    },
    {
      image: Detox,
      title: "DETOXIFICATION",
      link: "https://pg3-abstract.s3.us-east-1.amazonaws.com/abstract/CA_21_03_PE_PureGenomicsAbstracts_Detox.pdf",
    },
    {
      image: Fitness,
      title: "INJURY RISK",
      link: "https://pg3-abstract.s3.us-east-1.amazonaws.com/abstract/CA_21_03_PE_PureGenomicsAbstracts_InjuryRisk.pdf",
    },
    {
      image: Cardio,
      title: "CARDIOVASCULAR HEALTH",
      link: "https://pg3-abstract.s3.us-east-1.amazonaws.com/abstract/CA_21_03_PE_PureGenomicsAbstracts_CardiovascularHealth_V2.pdf",
    },
    {
      image: Fitness,
      title: "EXERCISE RESPONSE",
      link: "https://pg3-abstract.s3.us-east-1.amazonaws.com/abstract/CA_21_03_PE_PureGenomicsAbstracts_ExerciseResponse_V2.pdf",
    },
    {
      image: Glucose,
      title: "GLUCOSE METABOLISM",
      link: "https://pg3-abstract.s3.us-east-1.amazonaws.com/abstract/CA_21_03_PE_PureGenomicsAbstracts_GlucoseMetabolism_V2.pdf",
    },
    {
      image: Gastro,
      title: "GASTROINTESTINAL HEALTH",
      link: "https://pg3-abstract.s3.us-east-1.amazonaws.com/abstract/CA_21_03_PE_PureGenomicsAbstracts_GastrointestinalHealth_V3.pdf",
    },
    {
      image: Metabolic,
      title: "METABOLIC & WEIGHT MANAGEMENT",
      link: "https://pg3-abstract.s3.us-east-1.amazonaws.com/abstract/CA_21_03_PE_PureGenomicsAbstracts_WeightManagement.pdf",
    },
    {
      image: Memory,
      title: "COGNITIVE HEALTH & MEMORY",
      link: "https://pg3-abstract.s3.us-east-1.amazonaws.com/abstract/CA_21_03_PE_PureGenomicsAbstracts_CognitiveHealthandMemory_V3.pdf",
    },
    {
      image: Immune,
      title: "IMMUNE HEALTH",
      link: "https://pg3-abstract.s3.us-east-1.amazonaws.com/abstract/CA_21_03_PE_PureGenomicsAbstracts_ImmuneHealth_V2.pdf",
    },
    { image: "", title: "", link: "" },
  ];
  return (
    <>
      <div className="container-xxl">
        {/* section 2 */}
        <div className="my-5">
          <div className="d-inline-block cursor-pointer textc-primary pg-link my-2">
            <Link
              to="/hcp/educational_1"
              className="textc-primary text-decoration-none"
            >
              <i className="fas fa-chevron-left mr-2"></i> PureGenomics
              Education
            </Link>
          </div>
          <div className="row justify-content-lg-evenly justify-content-center my-5">
            <div className="col-11 col-lg-5 my-auto px-0">
              <div className="pgs-title my-lg-5">
                <span className="pgs-title__xl ff-v">
                  <span dangerouslySetInnerHTML={{ __html: t('puregemonics') }} />
                </span>{" "}
                <br />
                Research Abstracts
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <img className="img-fluid w-100" src={Tab} alt="tab" />
            </div>
          </div>
          {/* sub-section */}
          <div className="row justify-content-lg-evenly justify-content-center my-3 my-lg-5">
            {(isCA ? ca_research : research).map((rd) => {
              return (
                <ResearchAbstractTemplate
                  title={rd.title}
                  image={rd.image}
                  link={rd.link}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResearchAbstract;

const ResearchAbstractTemplate = ({ title, image, link }) => {
  return (
    <div className="col-11 col-lg-5 px-0 my-2 my-lg-5 ">
      <div className="d-block d-lg-flex">
        <div className={link ? "mx-3" : "d-none d-lg-block"}>
          <img
            src={image}
            style={{ height: "35px" }}
            alt={image}
            className="my-3 my-lg-0"
          />
        </div>
        <div className="">
          <div className="pgs-text textc-primary fw-bold mb-0 mb-lg-3 my-0 ">
            {title}
          </div>
          <a href={link} download className="">
            {link && "Download Abstract PDF"}
          </a>
        </div>
      </div>
    </div>
  );
};
